.btn_search{

    white-space: inherit;
    color: inherit;
    border-radius: var(--border-radius);
    display: block;
    width: 100%;
    border: 0;
    outline: none;
    background: var(--background);
    font-family: inherit;
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    --background: var(--ion-background-color, #fff);
    --border-radius: 2px;
    --box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    --cancel-button-color: var(--ion-color-step-900, #1a1a1a);
    --clear-button-color: initial;
    --color: var(--ion-color-step-850, #262626);
    --icon-color: var(--ion-color-step-600, #666666);
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: inherit;

    padding-left: 55px;
    padding-right: 55px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-position: left 8px center;
    height: auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    
}
.flexer,.progress-indicator {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex
}

.no-flexer,.progress-indicator.stacked {
    display: block
}

.no-flexer-element {
    -ms-flex: 0;
    -webkit-flex: 0;
    -moz-flex: 0;
    flex: 0
}

.flexer-element,.progress-indicator>li {
    -ms-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    flex: 1
}

.progress-indicator {
    margin: 0 0 1em;
    padding: 0;
    font-size: 80%;
    text-transform: uppercase
}

.progress-indicator>li {
    list-style: none;
    text-align: center;
    width: auto;
    padding: 0;
    margin: 0;
    position: relative;
    text-overflow: ellipsis;
    color: #bbb;
    display: block;
    font-size: 9px;
}

.progress-indicator>li:hover {
    color: #6f6f6f
}

.progress-indicator>li.completed,.progress-indicator>li.completed .bubble {
    color: #65d074
}

.progress-indicator>li .bubble {
    border-radius: 1000px;
    width: 20px;
    height: 20px;
    background-color: #bbb;
    display: block;
    margin: 0 auto .5em;
    border-bottom: 1px solid #888
}

.progress-indicator>li .bubble:after,.progress-indicator>li .bubble:before {
    display: block;
    position: absolute;
    top: 9px;
    width: 100%;
    height: 3px;
    content: '';
    background-color: #bbb
}

.progress-indicator>li.completed .bubble,.progress-indicator>li.completed .bubble:after,.progress-indicator>li.completed .bubble:before {
    background-color: #65d074;
    border-color: #247830
}

.progress-indicator>li .bubble:before {
    left: 0
}

.progress-indicator>li .bubble:after {
    right: 0
}

.progress-indicator>li:first-child .bubble:after,.progress-indicator>li:first-child .bubble:before {
    width: 50%;
    margin-left: 50%
}

.progress-indicator>li:last-child .bubble:after,.progress-indicator>li:last-child .bubble:before {
    width: 50%;
    margin-right: 50%
}

.progress-indicator>li.active,.progress-indicator>li.active .bubble {
    color: #337AB7
}

.progress-indicator>li.active .bubble,.progress-indicator>li.active .bubble:after,.progress-indicator>li.active .bubble:before {
    background-color: #337AB7;
    border-color: #122a3f
}

.progress-indicator>li a:hover .bubble,.progress-indicator>li a:hover .bubble:after,.progress-indicator>li a:hover .bubble:before {
    background-color: #5671d0;
    border-color: #1f306e
}

.progress-indicator>li a:hover .bubble {
    color: #5671d0
}

.progress-indicator>li.danger .bubble,.progress-indicator>li.danger .bubble:after,.progress-indicator>li.danger .bubble:before {
    background-color: #d3140f;
    border-color: #440605
}

.progress-indicator>li.danger .bubble {
    color: #d3140f
}

.progress-indicator>li.warning .bubble,.progress-indicator>li.warning .bubble:after,.progress-indicator>li.warning .bubble:before {
    background-color: #edb10a;
    border-color: #5a4304
}

.progress-indicator>li.warning .bubble {
    color: #edb10a
}

.progress-indicator>li.info .bubble,.progress-indicator>li.info .bubble:after,.progress-indicator>li.info .bubble:before {
    background-color: #5b32d6;
    border-color: #25135d
}

.progress-indicator>li.info .bubble {
    color: #5b32d6
}

.progress-indicator.stacked>li {
    text-indent: -10px;
    text-align: center;
    display: block
}

.progress-indicator.stacked>li .bubble:after,.progress-indicator.stacked>li .bubble:before {
    left: 50%;
    margin-left: -1.5px;
    width: 3px;
    height: 100%
}

.progress-indicator.stacked .stacked-text {
    position: relative;
    z-index: 10;
    top: 0;
    margin-left: 60%!important;
    width: 45%!important;
    display: inline-block;
    text-align: left;
    line-height: 1.2em
}

.progress-indicator.stacked>li a {
    border: none
}

.progress-indicator.stacked.nocenter>li .bubble {
    margin-left: 0;
    margin-right: 0
}

.progress-indicator.stacked.nocenter>li .bubble:after,.progress-indicator.stacked.nocenter>li .bubble:before {
    left: 10px
}

.progress-indicator.stacked.nocenter .stacked-text {
    width: auto!important;
    display: block;
    margin-left: 40px!important
}

@media handheld,screen and (max-width: 400px) {
    .progress-indicator {
        font-size:60%
    }
}

/*----------------------------------------------------------------------------------------*/
.cards-list {
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.card {
    margin: 15px 12px 15px 12px;
    width: 100px;
    height: 100px;
    border-radius: 20px;
    box-shadow: 2px 2px 2px 2px #d7d8da, 1px 1px 1px 1px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: 0.4s;
}

.card .card_image {
    width: inherit;
    height: inherit;
    border-radius: 40px;
}

.card_img_ {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    object-fit: cover;
}

.card .card_title {
    text-align: center;
    border-radius: 0px 0px 13px 13px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 15px;
    margin-top: -40px;
    height: 25px;
 
    background: rgb(0 0 0 / 48%) !important;
    position: relative;
}


.card:hover {
    transform: scale(0.9, 0.9);
    box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25),
        -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
}

.title-white {
    color: #fff;
}

.title-black {
    color: black;
}

@media all and (max-width: 500px) {
    .card-list {
        /* On small screens, we are no longer using row direction but column */
        flex-direction: column;
    }
}


/*
  .card {
    margin: 30px auto;
    width: 300px;
    height: 300px;
    border-radius: 40px;
    background-image: url('https://i.redd.it/b3esnz5ra34y.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
  box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    transition: 0.4s;
  }
  */


/* The heart of the matter */
.badge__ {
    background: #4c8dff;
    color: #f0f0f0;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.span_ {
    padding-left: 2px !important;
    padding-right: 2px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    width: 85px !important;
    height: 70px !important;

}

.ion-slide_s {
    width: 80px !important;
    height: 85px !important;
    background-color: #FFF;

    margin-left: 1.5px !important;
    margin-right: 1.5px !important;
    border-radius: 5px !important;
}

.horizontal-scrollable>.row_ {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    float: none;
}

.horizontal-scrollable>.row_>.col-xs-4 {
    display: inline-block;
    float: none;
}

/* Decorations */

.col-xs-4 {
    color: white;
    font-size: 24px;
    padding-bottom: 20px;
    padding-top: 18px;
}

.col-xs-4:nth-child(2n+1) {
    background: green;
}

.col-xs-4:nth-child(2n+2) {
    background: black;
}


.btn {
    background: transparent;
    border: 2px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    margin: 5px;
    padding: 10px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    outline: none;
    position: relative;
    top: 0;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.2s ease-in-out 0s;
    -moz-transition: all 0.2s ease-in-out 0s;
    -ms-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
}



.rounded {
    border-radius: 5px;
}

.full-rounded {
    border-radius: 20em;
}



.product-card {
    width: 'auto';
    position: relative;
    box-shadow: 0 2px 7px #dfdfdf;
    margin: 50px auto;
    background: #fafafa;
}

.badge {
    position: absolute;
    left: 0;
    top: 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    background: red;
    color: #fff;
    padding: 3px 10px;
}

.product-tumb {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    padding: 10px;
    background: #f0f0f0;
}

.product-tumb img {
    max-width: 100%;
    max-height: 100%;
}

.product-details {
    padding: 10px;
}

.product-catagory {
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ccc;
    margin-bottom: 18px;
}

.product-details h4 a {
    font-weight: 500;
    display: block;
    margin-bottom: 18px;
    text-transform: uppercase;
    color: #363636;
    text-decoration: none;
    transition: 0.3s;
}

.product-details h4 a:hover {
    color: #fbb72c;
}

.product-details p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 18px;
    color: #999;
}

.product-bottom-details {
    overflow: hidden;
    border-top: 1px solid #eee;
    padding-top: 20px;
}

.product-bottom-details div {
    float: left;
    width: 50%;
}

.product-price {
    font-size: 18px;
    color: #fbb72c;
    font-weight: 600;
}

.product-price small {
    font-size: 80%;
    font-weight: 400;
    text-decoration: line-through;
    display: inline-block;
    margin-right: 5px;
}

.product-links {
    text-align: right;
}

.product-links a {
    display: inline-block;
    margin-left: 5px;
    color: #e1e1e1;
    transition: 0.3s;
    font-size: 17px;
}

.product-links a:hover {
    color: #fbb72c;
}

.footer {
    padding: 5px !important;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.cardcenter {
    text-align: center;
}

ion-thumbnail {
    --border-radius: 10px;
    border: 1px solid var(--grayColor);

}