:root {
    --stickyBackground: #f5f5f5;
    --borderColor: #C9D1DC;
  }

  h1, h2 {
    margin-top: 48px;
  }
  
  .table-wrapper {
    border: 1px solid var(--borderColor);
    overflow: auto;
  }
  
  table {
    border-spacing: 0;
    width: 100%;
  }
  
  thead {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  
  tbody {
    font-size: 14px;
  }
  
  th, td {
    padding: 20px 5px 20px 5px !important;
    white-space: nowrap !important;
    border-bottom: 1px solid var(--borderColor);
  }
  
  tr:last-child td {
    border: none;
  }
  
  /*
  STICKY HEADER
  Normal css box-shadow works for the header as it is a single html element
  */
  
  .sticky-header thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--stickyBackground);
    box-shadow: 0 0 6px rgba(0,0,0,0.25);
  }
  
  
  /*
  STICKY FIRST COLUMN
  Avoid undesirable overlapping shadows by creating a faux shadow on the ::after psudo-element instead of using the css box-shadow property.
  */
  
  .sticky-column th:first-child,
  .sticky-column td:first-child {
    position: sticky;
    left: 0;
    background: var(--stickyBackground);
    border-right: 1px solid var(--borderColor);
  }
  
  .sticky-column th:first-child::after,
  .sticky-column td:first-child::after {
    content: "";
    position: absolute;
    right: -6px;
    top: 0;
    bottom: -1px;
    width: 5px;
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    opacity: 0.08;
  }


  .centerright {
    text-align: right;
  }
  .centeredfooteraccount {
    text-align: right;
    padding-right: 10px;
    font-size: 13px;
  }