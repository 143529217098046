.header,
.content,
.footer {
    position: absolute;
}

.header {
    top: 0;
    width: 100%;
    height: 60px;
    left: 0;
    right: 0;
}

.headerhide {
    top: 0;
    width: 100%;
    height: 0px;
    left: 0;
    right: 0;
}

.content {
    top: 60px;
    left: 0;
    right: 0;
    bottom: 140px;
}

.contenthide {
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 100%;
}

.footer {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 123px;
}

.footerhide {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0px;
}




.counter {
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-family: ‘Open Sans’;
    font-weight: 900;
    color: #202020;
    cursor: pointer;
}

.count {
    font-size: 20px;
    font-family: ‘Open Sans’;
    font-weight: 900;
    color: #202020;
}

.vertical_center {
    transform: translateY(20%);
}

.radius {
    --border-radius: 50% !important;
}

.shadow-red {
    --border-radius: 5px;
    --box-shadow: 0px 14px 25px rgba(182, 30, 30, 0.59);
}

.spancolor_red {
    background-color: rgba(239, 77, 86, .15) !important;
    color: #ef4d56 !important;
    -webkit-box-shadow: 0 0 13px 0 rgb(239 77 86 / 5%);
    box-shadow: 0 0 13px 0 rgb(239 77 86 / 5%);
    font-weight: bold !important;
    font-size: 13px !important;
}

.spancolor_success {
    background-color: rgba(34, 183, 131, .15) !important;
    color: #22b783 !important;
    -webkit-box-shadow: 0 0 13px 0 rgb(34 183 131 / 5%);
    box-shadow: 0 0 13px 0 rgb(34 183 131 / 5%);
    font-weight: bold !important;
    font-size: 13px !important;
}

.badge_ {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: .75em;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.footer {
    padding: 5px !important;
    position: absolute !important;
    bottom: 0 !important;
    width: 100%;
    z-index: 1 !important;
}

.item-native {
    padding: 0px !important;
    margin: 0px !important;
    padding-inline-start: 0px !important;
}

.inverted-border-radius {
    background-color: white;
    border-radius: 25px;
    vertical-align: baseline;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.verticalalign {
    vertical-align: middle;
    background: white;
}

.colorback {
    background: white !important;
}


.footerfixed {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
}

.heigmax {
    max-height: 50px;
}

.centeredfooter {
    text-align: right;
    
    font-size: 13px;
}
.centeredcenter {
    text-align: center;
    
    font-size: 13px;
}